







import ECharts from "@/components/charts/ECharts.vue";
import "echarts/lib/component/tooltip";
import "echarts/lib/component/legend";
import "echarts/lib/chart/bar";
import { Vue, Component } from "vue-property-decorator";
import theme from "@/components/charts/theme.json";

ECharts.registerTheme("ovilia-green", theme);

@Component({
  components: {
    ECharts
  }
})
export default class RevenueReport extends Vue {
  bar = {
    legend: {},
    tooltip: {},
    dataset: {
      // Provide data.
      source: [
        ["Product", "2015", "2016", "2017", "2018"],
        ["Matcha Latte", ...this.randomize()],
        ["Milk Tea", ...this.randomize()],
        ["Cheese Cocoa", ...this.randomize()],
        ["Walnut Brownie", ...this.randomize()]
      ]
    },
    // Declare X axis, which is a category axis, mapping
    // to the first column by default.
    xAxis: { type: "category" },
    // Declare Y axis, which is a value axis.
    yAxis: {},
    // Declare several series, each of them mapped to a
    // column of the dataset by default.
    series: [{ type: "bar" }, { type: "bar" }, { type: "bar" }, { type: "bar" }]
  };
    bar1 = {
    legend: {},
    tooltip: {},
    dataset: {
      // Provide data.
      source: [
        ["Product", "2015", "2016", "2017", "2019"],
        ["Matcha Latte", ...this.randomize()],
        ["Milk Tea", ...this.randomize()],
        ["Cheese Cocoa", ...this.randomize()],
        ["Walnut Brownie", ...this.randomize()]
      ]
    },
    // Declare X axis, which is a category axis, mapping
    // to the first column by default.
    xAxis: { type: "category" },
    // Declare Y axis, which is a value axis.
    yAxis: {},
    // Declare several series, each of them mapped to a
    // column of the dataset by default.
    series: [{ type: "bar" }, { type: "bar" }, { type: "bar" }, { type: "bar" }]
  };
  randomize() {
    return [0, 0, 0, 0].map(v => {
      return Math.round(300 + Math.random() * 700) / 10;
    });
  }
}
